import {
  isInvalidData,
  type BrandInvitationCheckError,
  type BrandInvitationCheckResult,
  type BrandInvitationValidationResult,
} from '~/constants/brandInvitations';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const token = to.query.token ? String(to.query.token) : undefined;

  if (!token) {
    return navigateTo('/login');
  }

  // Check if we already have the data in the route's meta
  if (to.meta.brandInvitation) {
    return;
  }

  // If we're on the server, fetch the data
  if (process.server) {
    const { isValid, data } = await validateToken(token);

    if (!isValid && isInvalidData(data)) {
      const notifications = useNotifications();
      notifications.showErrorNotification({ description: data.message });
    }

    // Store the result in the route's meta
    to.meta.brandInvitation = { isValid, data };
  } else {
    // On the client, we need to fetch the data if it's not already available
    const nuxtApp = useNuxtApp();
    if (!nuxtApp.payload.data[`brand-invitation-${token}`]) {
      const { isValid, data } = await validateToken(token);
      to.meta.brandInvitation = { isValid, data };
      // Store in Nuxt payload for future navigations
      nuxtApp.payload.data[`brand-invitation-${token}`] = { isValid, data };
    } else {
      to.meta.brandInvitation =
        nuxtApp.payload.data[`brand-invitation-${token}`];
    }
  }
});

async function validateToken(
  token: string
): Promise<BrandInvitationValidationResult> {
  try {
    const data = await $fetch<BrandInvitationCheckResult>(
      `/api/brand-invitations/${token}`
    );
    return {
      isValid: data.status === 'success',
      data: data.data,
    };
  } catch (error: any) {
    const typedError = error.data as BrandInvitationCheckError;

    return {
      isValid: false,
      data: typedError.data,
    };
  }
}
